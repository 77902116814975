import React from "react";

import styles from "./Logo.styles";

const Logo = () => {
	const { PUBLIC_URL } = process.env;
	const css = styles();

	return (
		<div className={css.logo}>
			<img
				alt="Kaiserlodge"
				height={84}
				src={PUBLIC_URL + "/img/kaiserlodge-icon.svg"}
				width={96}
			/>
		</div>
	);
};

export default Logo;
