/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import SVGIcon from "@Core/helper/svgicon";

import styles from "./Item.styles";

const Item = ({ icon, link, linkRel, target, title }) => {
	const { REACT_APP_PATH } = process.env;
	const css = styles();

	return (
		<>
			{target === "_blank" || target === "_top" ? (
				<a className={css.item} href={link} target={target} rel={linkRel}>
					{icon?.filename ? <SVGIcon src={REACT_APP_PATH + icon.filename} size={32} /> : ``}
					<Typography className={css.title} variant="h3">
						{title}
					</Typography>
				</a>
			) : (
				<Link className={css.item} to={link}>
					{icon?.filename ? <SVGIcon src={REACT_APP_PATH + icon.filename} size={32} /> : ``}
					<Typography className={css.title} variant="h3">
						{title}
					</Typography>
				</Link>
			)}
		</>
	);
};

export default Item;
